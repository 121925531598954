/*!
 * search.js
 * Created by Santiago Prada
 * Copyright © 2016 IBM. All rights reserved.
 */

var Search = function(parentView, applicationProperties) {

    var that = this;

    this.applicationProperties = applicationProperties;

    //Init Controller fuction
    this.init = function() {

        this.isMobile = function() {
            var m = (window.innerWidth > 769) ? false : true;
            return m
        };

        this.calculateOpenWidth = function() {
            var w = (that.isMobile()) ? ($("#mobile-nav-container").width() - 10) : 450;
            return w
        };

        this.getIconColor = function() {
            var c = (that.isMobile()) ? "#2c3e50" : "#111";
            return c
        };

        //Component Properties
        this.isSearchOpen = false;
        this.parentView = parentView;
        this.searchContainer = $("#search-component", this.parentView);
        this.searchIcon = $("#search-icon", this.parentView);
        this.searchForm = $("#search-form", this.parentView);
        this.clearButton = $("#close-search", this.parentView);
        this.textField = $("#search-text-field", this.parentView);

        //Add Action to the icon
        this.searchIcon.click(function() {
            //Open and close Action
            if (that.isSearchOpen == true) {
                that.closeSearch();
            } else {
                that.openSearch();
            }
        });

        //Clear Search
        this.clearButton.click(function() {
            that.closeSearch();
        });

        this.openSearch = function() {
            //Search is closed
            that.isSearchOpen = true;
            $("#language-selector").fadeOut(100);
            $("#navigation-component").fadeOut(100, function(){
              that.searchContainer.animate({
                  width: that.calculateOpenWidth()
              });
              that.searchIcon.css({
                  color: "#3FA9F5"
              });
              that.clearButton.show();
            });
        };

        this.closeSearch = function() {
            that.isSearchOpen = false;
            that.clearButton.hide();
            that.searchIcon.css({
                color: that.getIconColor()
            });
            that.searchContainer.animate({
                width: 40,
            }, function(){
              $("#language-selector").fadeIn(100);
              $("#navigation-component").fadeIn(100);
            });
        }
    };

    that.init();
};